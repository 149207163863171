html,
body {
  padding: 0;
  margin: 0;
  background: #333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.Waiting {
  color: #fafafa;
  font-size: 24px;
  margin: auto;
  text-align: center;
  top: 50%;
  position: absolute;
  width: 100%;
}
