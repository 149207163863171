.Button {
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgba(40, 200, 40, 1);
  width: 60px;
  height: 24px;
  line-height: 24px;
  border-radius: 10px;
  color: #fff;
  z-index: 1;
  opacity: 0.6;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.Button:hover {
  opacity: 0.7;
}

.Button--audio {
  right: 74px;
  border-radius: 10px 0 0 10px;
}

.Button--video {
  right: 10px;
  border-radius: 0px 10px 10px 0px;
}

.Button.is-off {
  background: rgba(200, 40, 40, 1);
}

.Button--screen,
.Button--screen.is-off {
  background: rgba(40, 40, 250, 1);
  left: 20;
  opacity: 0.5;
}
